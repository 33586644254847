export default {
  title_shortcuts: 'Atajos',
  subtitle_shortcuts: 'Puedes realizar las siguientes acciones',
  title_timeline: 'Timeline',
  subtitle_timeline: 'Elige que desea ver',

  components: {
    ACCIO: 'ActionSidebar',
    ADJUN: 'AttachmentSidebar',
    GLOSA: 'CommentSidebar',
    INSIS: 'InsistenceSidebar',
    MENSA: 'MessageSidebar',
    CHECK: 'ChecklistSidebar',
    GESTI: 'TicketSidebar',
    ASSIG: 'ProcessSidebar',
    DOCTO: 'DocumentSidebar',
    PRIOR: 'TicketPriority',
    DAREV: 'TicketCheck',
    TRATI: 'TransferTicket'
  },

  customer_filters: [
    {
      colorCode: 'link',
      icon: 'fas fa-tasks',
      isActive: false,
      functionalitiesCode: 'ACCIO',
      functionalitiesName: 'Acciones',
      permissionCode: 'ADACT',
      statusCode: 'VIGEN'
    },
    {
      colorCode: 'dark',
      icon: 'fas fa-paperclip',
      isActive: false,
      functionalitiesCode: 'ADJUN',
      functionalitiesName: 'Adjuntos',
      permissionCode: 'ADATC',
      statusCode: 'VIGEN'
    },
    {
      colorCode: 'info',
      icon: 'fas fa-comments',
      isActive: false,
      functionalitiesCode: 'GLOSA',
      functionalitiesName: 'Comentarios',
      permissionCode: 'ADNOC',
      statusCode: 'VIGEN'
    },
    {
      colorCode: 'success',
      icon: 'fas fa-clipboard-list',
      isActive: false,
      functionalitiesCode: 'GESTI',
      functionalitiesName: 'Gestiones',
      permissionCode: 'ADCPR',
      statusCode: 'VIGEN'
    },
    {
      colorCode: 'success',
      icon: 'fas fa-envelope',
      isActive: false,
      functionalitiesCode: 'MENSA',
      functionalitiesName: 'Mensajes',
      permissionCode: 'ADMEC',
      statusCode: 'VIGEN'
    },
    {
      colorCode: 'info',
      icon: 'fas fa-file',
      isActive: false,
      functionalitiesCode: 'DOCTO',
      functionalitiesName: 'Documentos',
      permissionCode: 'ADDOT',
      statusCode: 'VIGEN'
    }
  ],

  time_filters: {
    HORAS: 'Últimas 24 horas',
    SEMANA: 'Última semana',
    MES: 'Último mes',
    TODO: 'En cualquier fecha'
  },

  alert_action_not_found: 'No se encontraron acciones',
  alert_attachment_not_found: 'No se encontraron adjuntos',
  alert_comment_not_found: 'No se encontraron comentarios',
  alert_insistence_not_found: 'No se encontraron insistencias',
  alert_message_not_found: 'No se encontraron mensajes',
  alert_ticket_not_found: 'No se encontraron gestiones',
  alert_todo_no_customer: 'Sin cliente asociado para realizar la búsqueda',

  timeline_filter_placeholder: 'Utiliza el buscador para filtrar',
  select_timeline: 'Selecciona los elementos que visualizarás aquí',

  action: {
    date: 'Programado para el <%= date %>',
    responsible: 'Responsable <%= responsible %>'
  },
  attachment: {
    responsible: 'Adjuntado por <%= responsible %>',
    alert_delete_attachment_confirm: '¿Está seguro que desea eliminar el documento adjunto?',
    alert_delete_attachment_success: 'El documento adjunto ha sido eliminado satisfactoriamente'
  },
  comment: {
    description_responsible: 'Creada por <%= responsible %>',
    description_task: 'Creada en tarea <%= description %>',
    alert_delete_comment_confirm: '¿Está seguro que desea eliminar el comentario?',
    alert_delete_comment_success: 'El comentario ha sido eliminado satisfactoriamente'
  },
  history: {
    creation_info: 'En tarea <b>(<%= taskId %>) <%= taskName %></b> desde el <%= creationDate %> asignada a <%= userName %>',
    creation_title: '<% if (userName) { %> <%= userName %> (<%= roleName %>) <% if (replacementName) { %> Reemplazando a <%= replacementName %> por <%= replacementCause %> <% } %> <% } else { %> <%= roleName %> <% } %>',
    to_be_dispatched_info: 'Con plazo <%= deadlineDate %>',
    dispatch_info: '<% if (isDifferentUser) { %>(*)<% } %> Despachada el <%= termDate %> por <%= userNameDispatch %> mediante <b><%= eventName %></b>',
    no_childs: 'No se encontraron tareas hijas'
  },
  insistence: {
    responsible: 'Ingresada por <%= responsible %>'
  },
  todo: {
    ticket: 'Gestión'
  },
  btn_confirm_label: 'Sí',
  btn_cancel_label: 'No'
}
